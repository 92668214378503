import React from 'react';
import style from './index.module.scss';

const NotFoundPage: React.FC = () => {
  return (
    <div className={style.container}>
      <div className={style.header}>OUR POLICIES</div>
      <p>Effective Date: July,26 2023.</p>
      <h2 className={style.title}>1. Introduction</h2>
      <p className={style.text}>
        Welcome to Hexapus ACG . We are committed to protecting the privacy and
        security of your personal information. This Privacy Policy explains how
        we collect, use, and disclose information obtained through our
        e-commerce website www.hexapusacg.com.
      </p>
      <h2 className={style.title}>2. Information We Collect</h2>
      <p className={style.text}>a. Personal Information:</p>
      <p className={style.text}>- Name</p>
      <p className={style.text}>- Address</p>
      <p className={style.text}>- Email</p>
      <p className={style.text}>- address</p>
      <p className={style.text}>- Phone number</p>
      <p className={style.text}>
        - Payment information (credit/debit card details, etc.)
      </p>
      <p className={style.text}>
        - [Any other information you collect from users]
      </p>
      <p className={style.row}>b. Non-Personal Information:</p>
      <p className={style.text}>- IP address</p>
      <p className={style.text}>- Browser type</p>
      <p className={style.text}>- Device information</p>
      <p className={style.text}>- Cookies and similar technologies</p>
      <p className={style.text}>
        - [Any other non-personal information collected]
      </p>
      <h2 className={style.title}>3. How We Use Your Information</h2>
      <p className={style.text}>- Process and fulfill orders</p>
      <p className={style.text}>- Provide customer support</p>
      <p className={style.text}>- Send order and shipping notifications</p>
      <p className={style.text}>- Respond to inquiries and requests</p>
      <p className={style.text}>
        - Send marketing communications (with your consent)
      </p>
      <p className={style.text}>- Improve our website and services</p>
      <p className={style.text}>- Analyze user behavior and preferences </p>
      <p className={style.text}>- Prevent fraudulent activities</p>
      <h2 className={style.title}>4. Legal Basis for Data Processing</h2>
      <p className={style.text}>
        We process your personal information based on the following legal
        grounds:
      </p>
      <p className={style.text}>- Your consent</p>
      <p className={style.text}>- To fulfill a contract with you </p>
      <p className={style.text}>- Legitimate interests pursued by us</p>
      <p className={style.text}>- Compliance with legal obligations </p>
      <h2 className={style.title}>5. Sharing Your Information</h2>
      <p className={style.text}>We may share your information with:</p>
      <p className={style.text}>
        - Third-party service providers (payment processors, shipping companies,
        etc.)
      </p>
      <p className={style.text}>
        - Legal and regulatory authorities when required by law
      </p>
      <p className={style.text}>
        - Affiliates and business partners for marketing purposes (with your
        consent)
      </p>
      <h2 className={style.title}>6. Cookies and Tracking Technologies</h2>
      <p className={style.text}>
        We use cookies and similar technologies to collect non-personal
        information about your browsing activities on our website. You can
        manage your cookie preferences through your browser settings.
      </p>
      <h2 className={style.title}>7. Data Retention</h2>
      <p className={style.text}>
        We will retain your personal information for as long as necessary to
        fulfill the purposes outlined in this Privacy Policy, unless a longer
        retention period is required or permitted by law.
      </p>
      <h2 className={style.title}>8. Your Rights</h2>
      <p className={style.text}>You have the right to:</p>
      <p className={style.text}>
        - Access and obtain a copy of your personal information
      </p>
      <p className={style.text}>
        - Correct any inaccuracies in your information
      </p>
      <p className={style.text}>- Withdraw consent (where applicable)</p>
      <p className={style.text}>- Request the deletion of your data </p>
      <p className={style.text}>- Object to the processing of your data</p>
      <p className={style.text}>
        - Lodge a complaint with the relevant data protection authority
      </p>
      <h2 className={style.title}>9. Security Measures </h2>
      <p className={style.text}>
        We implement reasonable security measures to protect your personal
        information from unauthorized access and data breaches.
      </p>
      <h2 className={style.title}>10. Changes to this Privacy Policy</h2>
      <p className={style.text}>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page, and the &quot;Effective Date&quot; at the top will
        be revised accordingly.
      </p>
      <h2 className={style.title}>11. Contact Us</h2>
      <p className={style.text}>
        If you have any questions about this Privacy Policy, please contact us.
      </p>
      <p className={style.text}>
        By email: 
        <a href="mailto:info@hexapusacg.com" target="_blank" rel="noreferrer">
          info@hexapusacg.com
        </a>
      </p>
    </div>
  );
};

export default NotFoundPage;
