import React from 'react';
import style from './index.module.scss';

const NotFoundPage: React.FC = () => {
  return (
    <div className={style.container}>
      <div className={style.header}>Terms and Conditions</div>
      <p>Effective Date: Jan 01，2023.</p>
      <h2 className={style.title}>1. Preorder – Deposits</h2>
      <p className={style.text}>
        Based on preorder agreement that you agreed on prior to placing the
        order, if the order has passed 48 hours, the deposit won’t be refunded
        if cancelled. Because we are official dealer of the studios and we paid
        studio the deposit to secure the units, If a studio decides to cancel a
        project, the deposit paid will be fully refunded into store credit to
        you.
      </p>
      <h2 className={style.title}>2. In Stock items</h2>
      <p className={style.text}>
        There will be a 24 hours cooling period for in stock items, if you
        decide to cancel within 24 hours, full refund will be provided. After 24
        hours of purchase and before a tracking is provided, you can cancel the
        project with a 60% refund. After the tracking information is provided,
        we will not allow any cancellation.
      </p>
      <h2 className={style.title}>3. Installments</h2>
      <p className={style.text}>
        - For an in stock item, after you put down a deposit, we can help you
        create a PayPal Open Invoice or a website payment plan for at most 60-90
        days（depends on the item value). All payments are non-refundable since
        we secure the unit with the studio since payment 1 and Once you finish
        all the payments we will ship your item
      </p>
      <p className={style.text}>
        - For preorder, if you select payment plan, after you ordered via
        “payment plan”or “deposit”, you can message us here your order number.
        we can split your remaining payment into different payments based on
        your needs or we can help you create a PayPal open invoice till it
        release. Or you can choose to pay the remaining until the item released
      </p>

      <h2 className={style.title}>4. After-Sales Services</h2>
      <p className={style.text}>
        As long as it is a reasonable after sales and there is replacement (if
        it is a small QC problem or tiny breaks on a big part and you want a
        full replacement, studio won’t do it), studio will help for sure. If
        there is no replacement, there will be partial refund provided based on
        the damage assessment.
      </p>

      <br />
      <br />

      <p className={style.text}>
        At Hexapus ACG, we take after-sales services seriously and will do
        whatever we can to help our fans in this unfortunate situation.
      </p>

      <br />
      <br />
    </div>
  );
};

export default NotFoundPage;
